var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"about-teraphy",attrs:{"id":"about-teraphy"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-xx-lg-5 col-x-lg-6 col-lg-6 col-md-6 col-xs-12"},[_vm._m(1),_c('h3',{staticClass:"about-teraphy__subtitle"},[_vm._v(" Програма ТерапіяПЛЮС створена і реалізується міжнародною фармацевтичною компанією AstraZeneca, що на території України представлена ТОВ «АстраЗенека Україна». ")]),_c('p',{staticClass:"about-teraphy__text"},[_vm._v(" Програма ТерапіяПЛЮС діє на території України та поширюється на пацієнтів, яким лікарем було призначено лікарський засіб, міжнародна непатентована назва якого включена у Програму. Програма не поширюється на інші лікарські засоби, що прямо не включені до даної Програми, а також на лікарські засоби інших компаній, ніж АстраЗенека. Участь у Програмі є добровільною та безкоштовною. ")]),_c('p',{staticClass:"about-teraphy__text"},[_vm._v(" Більш детально ознайомитися з "),_c('router-link',{attrs:{"to":"/terms-and-conditions"}},[_vm._v(" Правилами та Умовами Програми ")]),_vm._v(" Ви можете нижче. ")],1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-xx-lg-7 col-x-lg-6 col-lg-6 col-md-6 col-xs-12"},[_c('h2',{staticClass:"about-teraphy__title"},[_vm._v(" Програма ТерапіяПЛЮС "),_c('span',[_vm._v("спрямована на:")])]),_c('div',{staticClass:"about-teraphy__list"},[_c('div',{staticClass:"about-teraphy__item"},[_vm._v(" Підвищення інформованості пацієнтів про захворювання ")]),_c('div',{staticClass:"about-teraphy__item"},[_vm._v(" Зниження вартості терапії для пацієнтів ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"about-teraphy__logo"},[_c('img',{attrs:{"src":require("@/assets/img/AZ_PANTONE_H_COL1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"about-teraphy__text"},[_c('b',[_vm._v("Шановні пацієнти!")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"about-teraphy__text"},[_c('b',[_vm._v("Інформуємо, що придбання лікарського засобу ФОРКСІГА (дапагліфлозин 10 мг) за програмою «ТерапіяПЛЮС» можливе до 28.02.2025 року включно.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"about-teraphy__text"},[_c('b',[_vm._v("Компанія продовжує забезпечувати доступність препарату Форксіга для пацієнтів.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"about-teraphy__text"},[_c('b',[_vm._v("За усіма питаннями щодо програми підтримки пацієнтів «ТерапіяПЛЮС» ви можете звернутись на гарячу лінію: 0 800 211 202 (щоденно з 9:00 до 21:00).")])])
}]

export { render, staticRenderFns }